import { ComponentProps } from 'react';

import { TrackedLink } from 'components/Link';

import { LegacyPrimaryButton as PrimaryButton } from './LegacyPrimaryButton';

export function TrackedPrimaryButtonLink(
  props: ComponentProps<typeof PrimaryButton> &
    ComponentProps<typeof TrackedLink>,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <PrimaryButton component={TrackedLink} type={null} {...props} />;
}

import { ReactElement, ReactNode } from 'react';

import { TrackedLink } from './TrackedLink';

type Props = {
  'aria-label'?: string;
  link: string | null | undefined;
  children: ReactNode;
  eventName?: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventProperties?: Record<string, any>;
  'data-qa-id'?: string;
  tabIndex?: number;
};

export function TrackedConditionalLink({
  link,
  children,
  ...props
}: Props): ReactElement {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!link) return <>{children}</>;

  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TrackedLink data-qa-id={props['data-qa-id']} href={link} {...props}>
      {children}
    </TrackedLink>
  );
}

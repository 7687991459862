import styled from 'styled-components';

import { colors, transition } from 'theme/theme';

import { ButtonWithStyles } from './ButtonWithStyles';

export const LegacyPrimaryButton = styled(ButtonWithStyles)<{
  $transparent?: boolean;
  $reverse?: boolean;
}>`
  transition: all ${transition};
  text-decoration: none;

  --legacy-primary-button-text-color: ${(props) =>
    props.color || (props.$reverse ? colors.brandBlue : colors.white)};

  color: var(--legacy-primary-button-text-color);
  background: ${(props) => (props.$reverse ? colors.white : colors.brandBlue)};
  border: 1px solid ${colors.brandBlue};
  ${(props) =>
    props.$transparent &&
    `
  background: transparent;
  border: 1px solid ${colors.white};`}

  &:hover {
    background-color: ${colors.hoverBlue};
    border: 1px solid ${colors.hoverBlue};
    color: var(--legacy-primary-button-text-color);
    text-decoration: none;
  }

  &:disabled {
    &:hover {
      background-color: ${colors.brandBlue};

      ${(props) =>
        props.$transparent &&
        `
  background-color: transparent;
`}
    }
  }
`;
